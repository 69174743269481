<template>
  <div>
    <div class="pl-3 pr-3 pb-3 flex flex-col">
      <div
        class="bg-primary p-5 rounded-md border border-transparent "
        id="colli-Log"
      >
        <div class="flex gap-4">
          <div class="text-white">{{ colliPosition.timestamp }}</div>
          <div class="ml-6">
            <location-icon :fillClass="'fill-white'"> </location-icon>
          </div>
          <div class="text-white">
            {{ colliPosition.position ? colliPosition.position.name : "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LocationIcon from "../../../components/icons/LocationIcon.vue";

export default defineComponent({
  name: "ProductCarrierPositionsContainer",
  components: { LocationIcon },
  props: {
    colliPosition: {
      type: Object,
    },
  },
});
</script>
