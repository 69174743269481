<template>
  <div>
    <!-- search inputs -->
    <finder-search-box
      :locationFilter="false"
      @update="
        (value) => {
          state.search = value;
        }
      "
      @filterDataField="
        (value) => {
          state.dataField = value;
        }
      "
    />

    <!--- Content-->
    <div
      v-if="GET_FINDER_COLLIS != null && GET_FINDER_COLLIS.length > 0"
      class="md:w-full h-full transition-all overflow-auto scrollbar scrollbar-dark pr-1 md:mr-1"
      id="finderScrollBox"
      :class="GET_FINDER_COLLI ? 'w-full' : 'w-full'"
    >
      <!-- colli list -->
      <div
        :id="'colli-' + colli.id"
        @click="jumpToColli(colli)"
        class=" bg-white cursor-pointer rounded-md h-auto flex-1 gap-8 mb-3 hover:border-grey-fontgrey"
        :class="
          GET_FINDER_COLLI.id == colli.id
            ? 'border-t-4 border-primary'
            : 'border border-transparent'
        "
        v-for="colli of GET_FINDER_COLLIS"
        :key="colli.id"
        :colli="colli"
      >
        <!--- colli item  -->
        <product-carrier-info-container
          :administrationView="false"
          :colli="colli"
          @showAllPositions="show_all_positions(colli)"
          @showPositions="jumpToColli(colli)"
          @showAllData="state.colli = colli"
        />

        <div v-if="state.showColliId == colli.id">
          <div class="w-full h-px bg-white-dark mb-5"></div>

          <product-carrier-positions-container
            v-for="colliPosition of colli.colliLogs"
            :key="colliPosition.id"
            :colliPosition="colliPosition"
          />
          <empty-list-feedback
            class="pb-4"
            v-if="colli.colliLogs == null || colli.colliLogs.length == 0"
            :text="$t('no_logs_for_colli')"
          ></empty-list-feedback>
        </div>
      </div>

      <!-- infinite loading-->
      <div class="w-full flex justify-center items-center">
        <infinite-loading
          :scrollBoxId="'finderScrollBox'"
          :isActive="!GET_ALL_COLLIS_LOADED"
          :emptyText="$t('no_more_collis')"
          @load="loadCollis()"
        />
      </div>
    </div>

    <!--empty feedback-->
    <div v-else>
      <empty-list-feedback
        class="pt-10 flex justify-center items-center"
        v-if="GET_FINDER_COLLIS == null || GET_FINDER_COLLIS.length == 0"
        :text="$t('no_collis')"
      ></empty-list-feedback>
    </div>

    <!--- edit location modal -->
    <product-carriers-content-modal
      :isVisible="state.openModal"
      @closeModal="set_back()"
    />

    <!-- collis data fields info modal -->
    <product-carrier-data-modal
      v-if="state.colli != null && state.colli.id != null"
      :colli="state.colli"
      :isVisible="state.colli != null && state.colli.id != null"
      @closeModal="state.colli = {}"
    ></product-carrier-data-modal>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import { AUTH_TYPES, FINDERS_TYPES, STORE_TYPES } from "../../../store/types";
import FinderSearchBox from "../components/FinderSearchBox.vue";
import { getData } from "../../../common/dataHelper";
import ProductCarrierInfoContainer from "../components/ProductCarrierInfoContainer.vue";
import ProductCarrierPositionsContainer from "../components/ProductCarrierPositionsContainer.vue";
import ProductCarrierDataModal from "../modals/ProductCarrierDataModal.vue";
import ProductCarriersContentModal from "../modals/ProductCarriersContentModal.vue";
import InfiniteLoading from "../../../components/global/InfiniteLoading.vue";
import {pusherStore } from "../../../common/thePusherService";

export default defineComponent({
  components: {
    ProductCarrierInfoContainer,
    ProductCarrierPositionsContainer,
    ProductCarriersContentModal,
    FinderSearchBox,
    EmptyListFeedback,
    ProductCarrierDataModal,
    InfiniteLoading,
  },
  emits: ["loadCollis"],
  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      search: "",
      openModal: false,
      colli: {},
      showAllPositions: false,
      showColliId: null,
      colliLocation: null,
      dataField: null,
    });

    /*
     * Computeds
     */
    const GET_FINDER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLIS}`
      ];
    });
    const GET_FINDER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLI}`
      ];
    });
    const GET_FINDER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_POSITIONS}`
      ];
    });
    const GET_ALL_COLLIS_LOADED = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_ALL_COLLIS_LOADED}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const GET_PAGE = computed(() => {
      return store.getters[`${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_PAGE}`];
    });
    // const GET_SEARCH = computed(() => {
    //   return store.getters[
    //     `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_SEARCH}`
    //   ];
    // });

    /*
     * COLLIS FILTER
     */
    const filteredCollis = computed(() => {
      if (GET_FINDER_COLLIS.value == null) {
        return;
      }

      return GET_FINDER_COLLIS.value.filter((colli) => {
        let searchBool = false;
        // let locationBool = false;
        let dataFieldBool = false;

        if (state.search == "" || state.search == null) {
          searchBool = true;
        }
        if (
          colli.name != null &&
          state.dataField == null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }

        if (colli.position != null) {
          if (
            colli.position.name != null &&
            colli.position.name.toLowerCase().match(state.search.toLowerCase())
          ) {
            return true;
          }
        }

        // ______________FILTER =>  Location_________________
        // if (state.colliLocation == "" || state.colliLocation == null) {
        //   locationBool = true;
        // } else if (
        //   colli.position != null &&
        //   colli.position.name.match(state.colliLocation.name)
        // ) {
        //   locationBool = true;
        // }
        //___________________________________________________________

        const fieldAttributes = getData(colli, "data.data");

        // FILTER =>  dataField
        if (fieldAttributes != null) {
          if (state.dataField == "" || state.dataField == null) {
            dataFieldBool = true;
          } else {
            const fieldKey = state.dataField.key;
            const fieldValue = fieldAttributes[fieldKey];
            if (fieldValue == null) {
              return false;
            }
            if (fieldKey != null) {
              if (fieldAttributes != null && fieldValue != null) {
                const element = fieldValue;

                if (
                  element
                    .toString()
                    .toLowerCase()
                    .match(state.search.toLowerCase())
                ) {
                  return true;
                }
              }
            }
          }
        }

        // FILTER in Collis => COLLI_POSITIONS
        const colliPositions = getData(colli, "colliPositions");
        if (colliPositions) {
          const hasMatch = colliPositions.some((colliPosition) => {
            const colliPositionName = colliPosition.name;
            if (colliPositionName != null) {
              if (
                colliPositionName
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              ) {
                return true;
              }
            }
          });
          if (hasMatch) {
            return true;
          }
        }

        if (fieldAttributes && state.dataField == null) {
          for (const key in fieldAttributes) {
            if (Object.hasOwnProperty.call(fieldAttributes, key)) {
              const element = fieldAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              ) {
                return true;
              }
            }
          }
        }
        return searchBool && dataFieldBool;
      });
    });

    /** Functions */

    // LoadCollis

    function loadCollis() {
        FETCH_FINDER_COLLIS();
    }

    const SET_FINDER_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.SET_FINDER_COLLI}`,
        colli
      );
    };
    function FETCH_FINDER_COLLIS() {
      const finderCollisData = {
        id: GET_USER.value.customer_id,
        params: {
          search: state.search,
          field: state.dataField ? state.dataField.key : null,
          page: GET_PAGE.value + 1,
        },
      };
      return store.dispatch(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.FETCH_FINDER_COLLIS}`,
        finderCollisData
      );
    }
    const colli_content = (colli) => {
      SET_FINDER_COLLI(colli);
      state.openModal = true;
    };
    const set_back = () => {
      SET_FINDER_COLLI({});
      state.openModal = false;
    };

    const show_all_positions = (colli) => {
      if (state.showAllPositions == false) {
        SET_FINDER_COLLI(colli);
        state.openModal = true;
        state.showAllPositions = true;
      } else {
        state.showAllPositions = false;
        SET_FINDER_COLLI({});
      }
    };

    const jumpToColli = (colli) => {
      // state.showAllPositions = true;
      state.showColliId = state.showColliId == colli.id ? null : colli.id;
      setTimeout(() => {
        let element = document.getElementById("colli-" + colli.id);
        if (
          element != null &&
          element.scrollIntoView != undefined &&
          state.showColliId == colli.id
        ) {
          element.scrollIntoView();
        }
      }, 20);
    };

    /**
     *  Mounted
     */
    onMounted(() => {});

    onUnmounted(()=>{
        pusherStore.unsubscribeChannels();
    });

    return {
      store,
      state,
      route,
      set_back,
      colli_content,
      show_all_positions,
      GET_FINDER_COLLI,
      GET_FINDER_COLLIS,
      GET_FINDER_POSITIONS,
      GET_ALL_COLLIS_LOADED,
      filteredCollis,
      jumpToColli,
      loadCollis,
    };
  },
});
</script>
